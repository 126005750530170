<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8">
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <form class="form" @submit.stop.prevent="formOnsubmit()">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <h3
                      class="
                        font-weight-bolder
                        text-dark
                        font-size-h4 font-size-h1-lg
                      "
                    >
                      Ubah Kelompok Kelas
                    </h3>
                    <b-form-group
                      id="input-group-name"
                      label="Nama:"
                      label-for="input-name"
                    >
                      <b-form-input
                        id="input-name"
                        v-model="form.name"
                        placeholder="Nama Kelompok Kelas"
                      ></b-form-input>
                      <small class="text-danger">{{ error.name }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-semester"
                      label="Semester:"
                      label-for="input-semester"
                    >
                      <treeselect
                        v-model="form.semester_id"
                        :multiple="false"
                        placeholder="Pilih Semester"
                        :options="semesters"
                      />
                      <small class="text-danger">{{
                        error.semester_id
                      }}</small>
                    </b-form-group>

                    <!-- <b-form-group
                        id="input-group-periode-type"
                        label="Jenis Periode:"
                        label-for="input-periode-type"
                      >
                        <treeselect
                          v-model="form.periode_type_id"
                          :multiple="false"
                          placeholder="Pilih Jenis Periode"
                          :options="periode_types"
                        />
                        <small class="text-danger">{{
                          error.periode_type_id
                        }}</small>
                      </b-form-group> -->

                    <b-form-group
                      id="input-group-grade"
                      label="Tingkat:"
                      label-for="input-grade"
                    >
                      <treeselect
                        v-model="form.grade_id"
                        :multiple="false"
                        placeholder="Pilih Tingkat"
                        :options="grades"
                      />
                      <small class="text-danger">{{ error.grade_id }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-major"
                      label="Prodi:"
                      label-for="input-major"
                    >
                      <treeselect
                        v-model="form.major_id"
                        :multiple="false"
                        placeholder="Pilih Prodi"
                        :options="majors"
                      />
                      <small class="text-danger">{{ error.major_id }}</small>
                    </b-form-group>

                    <b-form-group
                      id="input-group-users"
                      label="Dosen Wali:"
                      label-for="input-users"
                    >
                      <treeselect
                        v-model="form.user_id"
                        :multiple="false"
                        placeholder="Pilih Dosen Wali"
                        :options="teachers"
                      />
                      <small class="text-danger">{{ error.user_id }}</small>
                    </b-form-group>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <b-button type="submit" variant="primary"
                        >Simpan</b-button
                      >
                      <b-button
                        type="button"
                        class="ml-2"
                        variant="default"
                        @click="$router.push('/masters/class-groups')"
                      >
                        Batal
                      </b-button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-input-wrapper {
  width: 250px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      form: {
        name: "",
        semester_id: "",
        grade_id: "",
        major_id: "",
        user_id: "",
        periode_type_id: 0
      },
      error: {
        name: "",
        semester_id: "",
        grade_id: "",
        major_id: "",
        user_id: "",
        periode_type_id: ""
      },
      semesters: [],
      grades: [],
      majors: [],
      teachers: [],
      periode_types: []
    };
  },
  methods: {
    async get() {
      this.form = await module.get('api/class-groups/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/masters/class-groups')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
      }
    },

    async getGradeOption() {
      let response = await module.setTreeSelect("api/grades");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.grades = response.data;
        this.grades.unshift({
          label: "Pilih Tingkat",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getMajorOption() {
      let response = await module.setTreeSelect("api/majors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.majors = response.data;
        this.majors.unshift({
          label: "Pilih Prodi",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getSemesterOption() {
      let response = await module.setTreeSelect("api/semesters");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.semesters = response.data;
        this.semesters.unshift({
          label: "Pilih Semester",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getPeriodeTypeOption() {
      let response = await module.setTreeSelect("api/periode-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.periode_types = response.data;
        this.periode_types.unshift({
          label: "Pilih Jenis Periode",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getTeacherOption() {
      let response = await module.setTreeSelectTeacher("api/users", `?role_id=1`);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.teachers = response.data;
        this.teachers.unshift({
          label: "Pilih Dosen Wali",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, "api/class-groups/" + this.$route.params.id);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
        title: response.success.title,
        text: response.success.message,
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
        this.$router.push("/masters/class-groups");
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Kelompok Kelas", route: "/masters/class-groups" },
      { title: "Ubah Kelompok Kelas" },
    ]);

    this.get()
    this.getGradeOption()
    this.getMajorOption()
    this.getSchoolYearOption()
    this.getTeacherOption()
    this.getPeriodeTypeOption()
  },
};
</script>